import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GraciasView from '../views/GraciasView.vue'
import LoginView from '../views/LoginView.vue'
import AdminView from '@/views/AdminView.vue'
import { checkAuthentication } from '@/auth.js';
const routes = [
  {
    path: '/:optParam/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gracias',
    name: 'gracias',
    component: GraciasView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/admin/:optParam',
    name: 'admin',
    component: AdminView,
    meta: {
      requiresAuth: true, // Marca esta ruta como requerida autenticación
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !(await checkAuthentication())) {
    next('/login'); // Redirige al usuario a la página de inicio de sesión
  } else {
    next(); // Permite que la navegación continúe
  }
});
export default router
