<template>
  <div class="login-container">
    <div class="login-form">
      <h2>Iniciar sesión</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="user" class="label-login">Usuario:</label>
          <input type="text" class="input-login" id="user" v-model="username">
        </div>
        <div class="form-group">
          <label for="password" class="label-login">Contraseña:</label>
          <input type="password" class="input-login" id="password" v-model="password">
        </div>
        <button type="submit" class="button-login">Iniciar sesión</button>
      </form>
    </div>
  </div>
</template>

<script>
import { login } from '@/auth.js';
import bcrypt from 'bcryptjs';
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      json: {
        user: '',
        pass: ''
      }
    };
  },
  methods: {
    async encriptarContrasena(contra) {
      const saltRounds = 10; // Número de rondas de sal para la encriptación
      return await bcrypt.hash(contra, saltRounds);
    },
    async handleLogin() {
      this.json.user = this.username;
      this.json.pass = await this.encriptarContrasena(this.password);
      axios
          .post('https://votaciongh.uc.r.appspot.com/isValid', this.json)
          .then(response => {
            const valor = response.data; // Obtener la respuesta del servidor
            const success = login(valor, this.json);
            console.log(success)
            if (success) {
              this.$router.push('/admin/gala');
            } else {
              console.log('Credenciales inválidas');
              this.username = '';
              this.password = '';
            }
          })
          .catch(error => {
            console.error(error);
          });
    },
  },
};
</script>
