<template>
          <AdminGala v-if="optParam === 'gala'"/>
</template>

<script>
// @ is an alias to /src
import AdminGala from "@/components/Gala/Admin/AdminGala.vue";
export default {
  name: 'AdminView',
  components: {
    AdminGala,
  },
  computed: {
    optParam() {
      return this.$route.params.optParam;
    }
  },
}
</script>
