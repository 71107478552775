<template>
  <div class="modal-gala">
    <div class="modal-gala-close" @click="closeModal">
      <p>x</p>
    </div>
    <div class="modal-gala-content">
      <div class="modal-gala-content-title">
        <p>Vota por:</p>
        <h3>{{ dataForVotes.alias }}</h3>
      </div>
      <div class="modal-gala-content-buttons">
        <div class="modal-gala-content-buttons-sms">
          <span>Click para enviar SMS {{ dataForVotes.nombre }} al 3338</span>
          <button @click="enviarSMS" :disabled="!dataForVotes.buttonSms">
            <p>SMS - 1 VOTO</p>
            <h5>$590</h5>
          </button>
        </div>
        <button @click="accionB2" :disabled="!dataForVotes.buttonMpFirst">
          <p>4 VOTOS</p>
          <h5>$1990</h5>
        </button>
        <button @click="accionB3" :disabled="!dataForVotes.buttonMpSecond">
          <p>10 VOTOS</p>
          <h5>$3.990</h5>
          <img src="../../assets/gala/images/fire.png" alt="Icono Fuego">
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataForVotes: {
      type: Object,
      required: true,
      default: () => {},
    },
    modalIsActive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataForVotes: {
      handler(newValue) {
        console.log(newValue);
      },
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('changeModalIsActive', false);
    },
    accionB2() {
      this.$gtag.event('mp10_'+this.dataForVotes.nombre, {
        'event_value': this.dataForVotes.nombre
      });

      window.open(this.dataForVotes.buttonMpFirst, '_blank');
    },
    accionB3() {
      this.$gtag.event('mp20_'+this.dataForVotes.nombre, {
        'event_value': this.dataNombre
      });

      window.open(this.dataForVotes.buttonMpSecond, '_blank');
    },
    accionB4() {
      this.$gtag.event('mp5_'+this.dataForVotes.nombre, {
        'event_value': this.dataForVotes.nombre
      });
    },
    enviarSMS() {
      const ua = navigator.userAgent.toLowerCase();
      //const smsBody = 'Opcion'; // Cuerpo del mensaje

      var esAndroid = /Android/.test(navigator.userAgent);

      this.$gtag.event('click_SMS_'+this.dataForVotes.nombre, {
        'event_value': this.dataForVotes.nombre
      });

      if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        window.open(`sms://3338&body=${this.dataForVotes.nombre}`, '_system');
      } else if (esAndroid) {
        var versionAndroid = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf("Android") + 8));
        if (versionAndroid >= 4.4) {
          // A partir de Android 4.4 (KitKat), no se requiere el prefijo "//"
          window.open(`sms:3338?&body=${this.dataForVotes.nombre}`, '_system');
        } else {
          // Para versiones anteriores a Android 4.4, agrega "//" antes del número de teléfono
          window.open(`sms://3338?&body=${this.dataForVotes.nombre}`, '_system');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'avenir-regular';
  src: url('../../assets/gala/fonts/AVENIR-REGULAR.OTF');
}

@font-face {
  font-family: 'avenir-bold';
  src: url('../../assets/gala/fonts/AVENIR-BOLD.TTF');
}

.modal-gala{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  color: #FFF;
  font-family: 'avenir-regular';

  p, h3, h5{
    margin: 0;
  }

  &-close{
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #FFF;
    
    p{
      color: #000;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-content{
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 400px;

    &-title{

      p{
        margin-bottom: 8px;
      }

      h3{
        font-size: 32px;
        font-weight: bold;
      }

      span{
        font-size: 12px;
        font-weight: bold;
        color: #a7a7a7;
      }
    }

    &-buttons{
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      &-sms{
        position: relative;
        text-align: center;
        margin-bottom: 16px;

        span{
          font-size: 12px;
          font-weight: bold;
          color: #a7a7a7;
        }

        button{
          margin-top: 8px;        
        }
        
        &::before{
          content: '';
          position: absolute;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          background-color: #FFF;
        }
      }

      button{
        outline: none;
        border: none;
        background-image: linear-gradient(45deg, #2c2b5e, #111138);
        border-radius: 8px;
        padding: 6px 16px;
        color: #FFF;
        transition: all .3s;
        position: relative;
        width: 100%;
        overflow: hidden;
        
        p{
          font-family: 'avenir-bold';
          font-size: 16px;
          font-weight: bold;
        }

        h5{
          font-size: 14px;
        }

        &:hover{
          background-image: linear-gradient(45deg, #464580, #131325);
        }

        img{
          position: absolute;
          top: 65%;
          right: 25%;
          transform: translate(-50%, -50%);
          width: 20px;
        }
      }
    }
  }
}
</style>