// auth.js
// Estado de autenticación
import axios from "axios";

let isAuthenticated = false;

// Función para verificar si el usuario está autenticado
async function checkAuthentication() {
    // Lógica de verificación de autenticación
    // Puedes utilizar un estado global, un token de autenticación, etc.

    const token = localStorage.getItem('token');
    let json = {
        user: "token",
        pass: token
    }
    const response = await axios.post('https://votaciongh.uc.r.appspot.com/isValid', json);

    if (response.data === true){
        // Si el token existe y es válido, se considera al usuario autenticado
        isAuthenticated = !!token;
        return isAuthenticated;
    }
}

// Función para realizar el inicio de sesión
function login(valor, token) {
    if (valor === true) {
        // El inicio de sesión es exitoso, establecer el estado de autenticación a true
        isAuthenticated = true;
        // Guardar el token de autenticación en el local storage u otra forma de persistencia
        localStorage.setItem('token', token.pass);
        return true;
    } else {
        // Las credenciales son incorrectas, el inicio de sesión falló
        return false;
    }
}

// Función para cerrar la sesión
function logout() {
    // Lógica para cerrar la sesión
    // Puedes eliminar el token de autenticación, limpiar el estado, etc.

    // Ejemplo: Eliminar el token de autenticación del local storage
    localStorage.removeItem('token');
    // Establecer el estado de autenticación a false
    isAuthenticated = false;
}

// Exportar las funciones y el estado de autenticación
export { checkAuthentication, login, logout, isAuthenticated };