<template>
  <div class="modal-parent">
    <div class="gala">
      <main class="gala-container">
        <!-- BANNER -->
        <div class="gala-banner">
          <img src="../../assets/galeria/banner_festival.jpg" alt="Banner" class="img-fluid mx-auto"/>
          <div class="gala-banner-text">
            <p class="text-white m-0">Vota por tu artista favorito</p>
            <p class="text-white m-0">Participa por $500.000</p>
          </div>
        </div>
        <!-- GRILLA -->
        <div class="gala-grid">
          <div class="gala-grid-item" v-for="(item, i) in jsonData" :key="i">
            <div class="gala-grid-item-img">
              <img srcset="" :src="validFoto(item.imageLink)" :alt="altText(item.imageLink, item.nombre)" class="fes-lc-votacion-img img-fluid" @click="llamarModal" />
              <h2>{{ item.nombre }}</h2>
            </div>
            <div class="gala-grid-item-button">
              <button @click="sendDataForModal(item.nombre)">Votar</button>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <a href="https://concursos.mega.cl/home/" target="_blank">Base del concurso</a>
        <div>
          <p>Desarrollado por</p>
          <a href="mailto:contacto@celcom.cl" target="_blank">
            <img src="../../assets/galeria/logo_celcom_blanco.svg" alt="Logo Celcomlatam blanco"/>
          </a>
        </div>
      </footer>
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center p-0">
          <div v-if="jsonData && !hayParticipantesActivos" class="got-votacion justify-content-center text-white text-uppercase">
            <h1 class="py-5 display-1">¡Votaciones Cerradas!</h1>
          </div>
          <div v-else class="gh-votacion justify-content-center text-white text-uppercase"/>
        </div>
      </div>
    </div>
    <ModalVotacionGala
      v-if="modaIsActive"
      :dataForVotes="dataForVotes"
      :modaIsActive="modaIsActive"
      @changeModalIsActive="changeModalIsActive"
    />
    <LoaderComponent v-if="loaderIsActive"/>
  </div>
</template>

<script>
import ModalVotacionGala from "./ModalVotacionFestival.vue";
import axios from "axios";
import { logout } from '@/auth.js';
import LoaderComponent from "../LoaderComponent/LoaderComponent.vue";

export default {
  name: 'FestivalView',
  components: {
    ModalVotacionGala,
    LoaderComponent
  },
  data() {
    return {
      jsonData: null,
      dataForVotes: {},
      modaIsActive: false,
      loaderIsActive: true,
    };
  },
  computed: {
    hayParticipantesActivos() {
      return this.jsonData.some(item => item.active);
    },
  },
  methods: {
    validFoto(dataFoto) {
      try {
        return require(`@/assets/galeria/${dataFoto}`);
      } catch (error) {
        return require('../../assets/galeria/lnd_got_header_hq.png'); 
      }
    },
    altText(dataFoto, dataNombre) {
      return dataFoto ? `Foto de ${dataNombre}` : 'Foto del Participante';
    },
    sendDataForModal(name){
      this.dataForVotes = {};
      this.modaIsActive = true;
      setTimeout(() => {
        const selectedItem = this.jsonData.find(item => item.nombre === name);
        this.dataForVotes = selectedItem;
      }, 10);
    },
    async cargarData() {
      await axios.get("https://votaciongh.uc.r.appspot.com/participantes_general/lp/4")
      //axios.get("http://192.168.1.7:3113/participantes1")
        .then(response => {
          this.jsonData = response.data.filter(item => item.active && item.category === null)
        })
        .finally(() => {
          this.loaderIsActive = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    changeModalIsActive(value) {
      this.modaIsActive = value;
    },
  },
  created() {
    logout();
  },
  mounted() {
    this.cargarData();
  },
}
</script>
<style scoped lang="scss">
@font-face {
  font-family: 'avenir-regular';
  src: url('../../assets/gala/fonts/AVENIR-REGULAR.OTF');
}

@font-face {
  font-family: 'avenir-bold';
  src: url('../../assets/gala/fonts/AVENIR-BOLD.TTF');

}

.gala{
  background-image: url('../../assets/gala/images/background.png');
  background-position: center;
  background-size: cover;
  font-family: 'avenir-regular';
  min-height: 100vh;
  padding-bottom: 24px;
  position: relative;

  p{
    margin: 0;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  &-banner{
    position: relative;

    &-logo{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
    }

    &-text{
      background-color: #000;
      color: #FFF;
      text-align: center;
      padding: 8px 16px;
      font-size: 14px; 
    }
  }

  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000;
    color: #FFF;
    padding: 8px 0;
    font-size: 14px;
    text-align: center;

    a{
      text-decoration: underline;
    }

    div{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      a{
        text-decoration: none;
        
        img{
          margin-top: -4px;
          width: 70px;
        }
      } 
    }
  }
}

.gala-container {
  width: 100%;
  margin: 0 auto;
  
  // Tamaño por defecto (Mobile)
  max-width: 480px;

  // Tablet
  @media (min-width: 481px) {
    max-width: 640px;
  }

  // Desktop
  @media (min-width: 1024px) {
    max-width: 960px;
  }
}

.gala-grid {
  padding: 16px;
  display: grid;
  gap: 16px; 

  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 481px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }

  &-item{
    position: relative;
    padding: 8px;
    background-image: linear-gradient(45deg, #2c2b5e, #111138);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
    p{
      font-size: 14px;
      color: #FFF;
    }

    &-img{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      text-align: center;

      img{
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }

      h2{
        font-size: 16px;
        font-family: 'avenir-bold';
        color: #FFF;
      }
    }

    &-button{
      position: relative;
      margin-top: 4px;
      text-align: center;
      width: 100%;

      button{
        outline: none;
        border: none;
        border-radius: 12px;
        background: linear-gradient(45deg, #ff9800, #ff5722); /* Tonos cálidos del logo */
        font-size: 14px;
        font-weight: bold;
        color: #FFF;
        padding: 6px 16px;
        letter-spacing: 0.5px;
        width: 100%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 4px 12px rgba(255, 152, 0, 0.4); /* Resaltado */
        margin-bottom: 8px;
      }
    }
  }
}

.modal-parent{
  overflow: hidden;
}
</style>
